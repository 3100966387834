export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは10月より%d点上がりました。その調子です！寒さに負けず、良い生活習慣を身につけられていますね。<br>\n12月のテーマは、<b>❝１年頑張った自分を労わろう​❞​</b>   です。2023年も残りわずかとなりました。身体や心の不調と改めて向き合う知識をつけながら、年末年始を健康的に過ごすための情報をお届けします。\n\n",
      "down": "生活習慣スコアは10月より%d点下がりました。分類別評価でどの項目に変化があったのか振り返ってみましょう。<br>\n12月のテーマは、<b>❝１年頑張った自分を労わろう​❞​</b>​   です。2023年も残りわずかとなりました。身体や心の不調と改めて向き合う知識をつけながら、年末年始を健康的に過ごすための情報をお届けします。\n\n",
      "equal100": "生活習慣スコアは10月に引き続き%満点%です。寒さに負けず、大変素晴らしいです！<br>\n12月のテーマは、<b>❝１年頑張った自分を労わろう​❞​</b>​   です。2023年も残りわずかとなりました。身体や心の不調と改めて向き合う知識をつけながら、年末年始を健康的に過ごすための情報をお届けします。\n\n",
      "equal": "生活習慣スコアは10月と同じ点数です。<br>\n12月のテーマは、<b>❝１年頑張った自分を労わろう​❞​​</b>   です。2023年も残りわずかとなりました。身体や心の不調と改めて向き合う知識をつけながら、年末年始を健康的に過ごすための情報をお届けします。\n\n"
    },
    "steps": {
      "up": "歩数スコアは10月より%d点上がりました。<br>\n12月19日（火）からWalk チーム対抗戦のエントリーが始まります。仲間と一緒に楽しく続けながら、運動習慣を身に付けよう。\n\n",
      "down": "歩数スコアは10月より%d点下がりました<br>\n12月19日（火）からWalk チーム対抗戦のエントリーが始まります。仲間と一緒に楽しく続けながら、運動習慣を身に付けよう。\n\n",
      "equal": "歩数スコアは10月と同じ点数です。<br>\n12月19日（火）からWalk チーム対抗戦のエントリーが始まります。仲間と一緒に楽しく続けながら、運動習慣を身に付けよう。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは10月より%d点上がりました。<br>\n寒くなって血流が停滞すると、筋肉に栄養が届きにくくなり疲労物質などが溜まりやすい状態に。<a href=\"https://go.andwell.jp/library/77efc104-884b-11ee-92e0-06e9236fa737\">こちらのコラム</a>では、血流改善のためのコツをお伝え！\n\n",
      "down": "運動のスコアは10月より%d点下がりました。<br>\n寒くなって血流が停滞すると、筋肉に栄養が届きにくくなり疲労物質などが溜まりやすい状態に。<a href=\"https://go.andwell.jp/library/77efc104-884b-11ee-92e0-06e9236fa737\">こちらのコラム</a>では、血流改善のためのコツをお伝え！\n\n",
      "equal": "運動のスコアは10月と同じ点数です。<br>\n寒くなって血流が停滞すると、筋肉に栄養が届きにくくなり疲労物質などが溜まりやすい状態に。<a href=\"https://go.andwell.jp/library/77efc104-884b-11ee-92e0-06e9236fa737\">こちらのコラム</a>では、血流改善のためのコツをお伝え！\n\n"
    },
    "meal": {
      "up": "食事のスコアは10月より%d点上がりました。<br>\n飲み会や会食の予定がある日は、その前後で調整しましょう。全く食べないのではなく、量を少なくしたり、野菜を多く食べるように心がけて。\n\n",
      "down": "食事のスコアは10月より%d点下がりました。<br>\n飲み会や会食の予定がある日は、その前後で調整しましょう。全く食べないのではなく、量を少なくしたり、野菜を多く食べるように心がけて。\n\n",
      "equal": "食事のスコアは10月と同じ点数です。<br>\n飲み会や会食の予定がある日は、その前後で調整しましょう。全く食べないのではなく、量を少なくしたり、野菜を多く食べるように心がけて。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは10月より%d点上がりました。<br>\n飲みすぎる日が減っていますね！その調子で年末年始も上手にお酒と付き合ってください。\n\n",
      "down": "飲酒のスコアは10月より%d点下がりました。<br>\n飲みすぎる日が増えていますね。<a href=\"https://go.andwell.jp/library/c6e5afe5-d2d2-11ed-8e3c-06528b823331\">こちらのコラム</a>では、二日酔い予防法や身体を労るおつまみの選び方を伝授！\n\n",
      "equal": "飲酒のスコアは10月と同じ点数です。<br>\n<a href=\"https://go.andwell.jp/library/c6e5afe5-d2d2-11ed-8e3c-06528b823331\">こちらのコラム</a>では、二日酔い予防法や身体を労るおつまみの選び方を伝授！\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは10月より%d点上がりました。<br>\n<b>朝日を浴びること</b>で生活リズムを整えられます。起きたらカーテンを開けて、太陽の光を部屋に取り込みましょう。\n\n",
      "down": "睡眠のスコアは10月より%d点下がりました。<br>\n<b>朝日を浴びること</b>で生活リズムを整えられます。起きたらカーテンを開けて、太陽の光を部屋に取り込みましょう。\n\n",
      "equal": "睡眠のスコアは10月と同じ点数です。<br>\n<b>朝日を浴びること</b>で生活リズムを整えられます。起きたらカーテンを開けて、太陽の光を部屋に取り込みましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは10月より%d点上がりました。<br>\n「深呼吸をする」「人と話す」「ゆっくりお風呂に浸かる」など、どれもストレス解消に繋がります。忙しい時こそ、こまめな息抜きが大切♪\n\n",
      "down": "ストレスのスコアは10月より%d点下がりました。<br>\n「深呼吸をする」「人と話す」「ゆっくりお風呂に浸かる」など、どれもストレス解消に繋がります。忙しい時こそ、こまめな息抜きが大切♪\n\n",
      "equal": "ストレスのスコアは10月と同じ点数です。<br>\n「深呼吸をする」「人と話す」「ゆっくりお風呂に浸かる」など、どれもストレス解消に繋がります。忙しい時こそ、こまめな息抜きが大切♪\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your Lifestyle Score has gone up by %d points since October. You're developing good lifestyle habits despite the cold weather.<br>\nThe theme for December is \"Let's take good care of ourselves after a year of hard work.” We're going to give you some tips on how to stay healthy over the year-end and New Year's holidays, and how to tackle some of the physical and mental problems they can bring.\n\n",
      "down": "Your Lifestyle Score has dropped by %d points since October. Look back to see if anything has changed in the categorized evaluation.<br>\nThe theme for December is \"Let's take good care of ourselves after a year of hard work.” We're going to give you some tips on how to stay healthy over the year-end and New Year's holidays, and how to tackle some of the physical and mental problems they can bring.\n\n",
      "equal100": "Your Lifestyle score is perfect, just as it was in October. You're doing great despite the cold weather!<br>\nThe theme for December is \"Let's take good care of ourselves after a year of hard work.” We're going to give you some tips on how to stay healthy over the year-end and New Year's holidays, and how to tackle some of the physical and mental problems they can bring.\n\n",
      "equal": "Your Lifestyle score is the same as it was in October.<br>\nThe theme for December is \"Let's take good care of ourselves after a year of hard work.” We're going to give you some tips on how to stay healthy over the year-end and New Year's holidays, and how to tackle some of the physical and mental problems they can bring.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since October.<br>\nWe'll start taking entries for the Team Walk Competition on Tuesday, December 19. Make exercise a habit while you have fun keeping it up with friends.\n\n",
      "down": "Your steps score has gone down %d pts since October. <br>\nWe'll start taking entries for the Team Walk Competition on Tuesday, December 19. Make exercise a habit while you have fun keeping it up with friends.\n\n",
      "equal": "Your steps score is the same as October. <br>\nWe'll start taking entries for the Team Walk Competition on Tuesday, December 19. Make exercise a habit while you have fun keeping it up with friends.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since October.<br>\nSluggish blood flow due to cold weather makes it harder for nutrients to reach muscles, and easier for fatigue-causing substances to accumulate. <a href=\"https://go.andwell.jp/library/77efc104-884b-11ee-92e0-06e9236fa737\">This column</a> has tips on how to improve your blood flow!\n\n",
      "down": "Your exercise score has gone down %d pts since October.<br>\nSluggish blood flow due to cold weather makes it harder for nutrients to reach muscles, and easier for fatigue-causing substances to accumulate. <a href=\"https://go.andwell.jp/library/77efc104-884b-11ee-92e0-06e9236fa737\">This column</a> has tips on how to improve your blood flow!\n\n",
      "equal": "Your exercise score is the same as October.<br>\nSluggish blood flow due to cold weather makes it harder for nutrients to reach muscles, and easier for fatigue-causing substances to accumulate. <a href=\"https://go.andwell.jp/library/77efc104-884b-11ee-92e0-06e9236fa737\">This column</a> has tips on how to improve your blood flow!\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since October.<br>\nWhen you have drinking or dinner party plans, adjust your habits on the surrounding days to make allowances for them. Rather than not eating at all, try to eat less, and to eat more vegetables.\n\n",
      "down": "Your food score has gone down %d pts since October.<br>\nWhen you have drinking or dinner party plans, adjust your habits on the surrounding days to make allowances for them. Rather than not eating at all, try to eat less, and to eat more vegetables.\n\n",
      "equal": "Your food score is the same as October.<br>\nWhen you have drinking or dinner party plans, adjust your habits on the surrounding days to make allowances for them. Rather than not eating at all, try to eat less, and to eat more vegetables.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since October.<br>\nYou're overdrinking on fewer days! Keep up the good work, and continue to drink wisely over the year-end and New Year's holidays.\n\n",
      "down": "Your alcohol score has gone down %d pts since October.<br>\nYou're overdrinking on more days.<a href=\"https://go.andwell.jp/library/c6e5afe5-d2d2-11ed-8e3c-06528b823331\">This column</a> tells you how to choose snacks that'll help you avoid a hangover!\n\n",
      "equal": "Your alcohol score is the same as October.<br>\n <a href=\"https://go.andwell.jp/library/c6e5afe5-d2d2-11ed-8e3c-06528b823331\">This column</a> tells you how to choose snacks that'll help you avoid a hangover!\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since October.<br>\n<b>Getting some sunshine in the morning</b> can help you keep your lifestyle rhythm in good shape. When you wake up, open the curtains and let the sunlight in.\n\n",
      "down": "Your sleep score has gone down %d pts since October.<br>\n<b>Getting some sunshine in the morning</b> can help you keep your lifestyle rhythm in good shape. When you wake up, open the curtains and let the sunlight in.\n\n",
      "equal": "Your sleep score is the same as October.<br>\n<b>Getting some sunshine in the morning</b> can help you keep your lifestyle rhythm in good shape. When you wake up, open the curtains and let the sunlight in.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since October.<br>\nHere are some things that can help relieve stress: breathing deeply, talking to people, and taking a long, relaxing bath. When you're busy, it's especially important to take frequent breaks♪\n\n",
      "down": "Your stress score has gone down %d pts since October.<br>\nHere are some things that can help relieve stress: breathing deeply, talking to people, and taking a long, relaxing bath. When you're busy, it's especially important to take frequent breaks♪\n\n",
      "equal": "Your stress score is the same as October.<br>\nHere are some things that can help relieve stress: breathing deeply, talking to people, and taking a long, relaxing bath. When you're busy, it's especially important to take frequent breaks♪\n\n"
    }
  }
}